import { ChainId } from 'gdcswap_sdk'
import React, { useMemo, useRef, useState } from 'react'
import { isMobile } from 'react-device-detect'
import { Text } from 'rebass'
import { NavLink, useLocation } from 'react-router-dom'
import styled from 'styled-components'
import { ReactComponent as Close } from '../../assets/images/x.svg'
import { ReactComponent as DropDown } from '../../assets/images/dropdown.svg'
import { ReactComponent as Twitter } from '../../assets/svg/twitter.svg'
// import { ReactComponent as Tg } from '../../assets/svg/tg.svg'
import { ReactComponent as Discord } from '../../assets/svg/discord.svg'

import Modal from 'components/Modal'

// import Logo from '../../assets/svg/logo.svg'
// import LogoDark from '../../assets/svg/logo_white.svg'
// import Wordmark from '../../assets/svg/wordmark.svg'
// import WordmarkDark from '../../assets/svg/wordmark_white.svg'
import { useActiveWeb3React } from '../../hooks'
// import { useDarkModeManager } from '../../state/user/hooks'
import { useETHBalances } from '../../state/wallet/hooks'

import { YellowCard } from '../Card'
import Settings from '../Settings'
// import Menu from '../Menu'

import Row, { RowBetween } from '../Row'
import Web3Status from '../Web3Status'
import { useOnClickOutside } from 'hooks/useOnClickOutside'
// import VersionSwitch from './VersionSwitch'

const HeaderFrame = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
  top: 0;
  position: absolute;
  z-index: 2;
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    padding: 12px 0 0 0;
    width: calc(100%);
    position: relative;
  `};
`

const HeaderElement = styled.div`
  display: flex;
  align-items: center;
`

const BannerElement = styled.div`
    display: flex;
    align-items: center;
    margin-left: 100px;
    gap: 64px;
    ${({ theme }) => theme.mediaWidth.upToSmall`
    flex-direction: column;
    margin-left: 0px;
    gap: 28px;
    align-items: flex-start;
    padding:44px 32px;
  `};

`

const BannerItem = styled(NavLink) <{ active: boolean }>`
  font-size: 18px;
  line-height: 21px;
  color:${({ active }) => (active ? 'transparent' : '#646E6B')};
  background: ${({ active }) => active ? ' linear-gradient(180deg, #EAF10C 0%, #A1FE02 100%)' : ''};
  cursor: pointer;
  background-clip: text;
  text-decoration-line: none;
  transition: all .1s linear;
  display: flex;
  align-items: center;
  position: relative;
  :hover {
    color:${({ active }) => (active ? 'transparent' : 'white')};
    ${({ active }) => active ? ' linear-gradient(180deg, #EAF10C 0%, #A1FE02 100%)' : ''};
    svg {
      path {
        stroke: white;
      }
    }
    .menu-glyout{
      color:#646E6B;
    }
  }
`

const BannerItem1 = styled.div <{ active?: boolean, isMobile: boolean }>`
  font-size: 18px;
  line-height: 21px;
  color:${({ active }) => (active ? 'transparent' : '#646E6B')};
  background: ${({ active }) => active ? ' linear-gradient(180deg, #EAF10C 0%, #A1FE02 100%)' : ''};
  cursor: pointer;
  background-clip: text;
  text-decoration-line: none;
  transition: all .1s linear;
  display: flex;
  flex-direction: ${({ isMobile }) => isMobile ? 'column' : 'row'};
  gap:28px;
  align-items:${({ isMobile }) => isMobile ? 'flex-start' : 'center'};
  position: relative;
  :hover {
    color:${({ active }) => (active ? 'transparent' : 'white')};
    ${({ active }) => active ? ' linear-gradient(180deg, #EAF10C 0%, #A1FE02 100%)' : ''};
    svg {
      path {
        stroke: white;
      }
    }
    .menu-glyout{
      color:#646E6B;
    }
  }
`

const HeaderElementWrap = styled.div`
  display: flex;
  align-items: center;


  /* ${({ theme }) => theme.mediaWidth.upToSmall`
    margin-top: 0.5rem;
`}; */
`

const Title = styled.a`
  display: flex;
  align-items: center;
  pointer-events: auto;

  :hover {
    cursor: pointer;
  }
`

const TitleText = styled(Row)`
  width: fit-content;
  white-space: nowrap;
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    display: none;
  `};
`

const AccountElement = styled.div<{ active: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: ${({ theme, active }) => (!active ? '#2A3910' : theme.bg3)};
  border-radius: 12px;
  white-space: nowrap;
  width: 100%;

  :focus {
    border: 1px solid blue;
  }
`

const TestnetWrapper = styled.div`
  white-space: nowrap;
  width: fit-content;
  margin-left: 10px;
  pointer-events: auto;
`

const NetworkCard = styled(YellowCard)`
  width: fit-content;
  margin-right: 10px;
  border-radius: 12px;
  padding: 8px 12px;
`

const UniIcon = styled.div`
  transition: transform 0.3s ease;
  :hover {
    transform: rotate(-5deg);
  }
  ${({ theme }) => theme.mediaWidth.upToSmall`
    img {
      width: 4.5rem;
    }
  `};
`

const HeaderControls = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    flex-direction: row;
    align-items: center;
  `};
`

const BalanceText = styled(Text)`
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    display: none;
  `};
`

const Wrapper = styled.div`
  ${({ theme }) => theme.flexColumnNoWrap}
  margin: 0;
  padding: 0;
  width: 100%;
`
const CloseIcon = styled.div`
  position: absolute;
  right: 1rem;
  top: 14px;
  &:hover {
    cursor: pointer;
    opacity: 0.6;
  }
`

const CloseColor = styled(Close)`
  path {
    stroke: ${({ theme }) => theme.text4};
  }
`

const StyledDropDown = styled(DropDown)`
  margin: 0 0.25rem 0 0.5rem;
  height: 35%;
  path {
    stroke: ${({ theme }) => '#646E6B' || theme.white};
    transition: all .1s linear;
    stroke-width: 1.5px;
  }
`

const MenuFlyout = styled.div`
  min-width: 160px;
  background-color: ${({ theme }) => theme.bg1};
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.01), 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 16px 24px rgba(0, 0, 0, 0.04),
    0px 24px 32px rgba(0, 0, 0, 0.01);

  border: 1px solid ${({ theme }) => theme.bg3};

  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  font-size: 1rem;
  position: absolute;
  top: 3rem;
  left: 0rem;
  z-index: 100;
  padding: 16px;
  gap: 16px;

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    min-width: 18.125rem;
    right: -46px;
  `};
  &:hover{
    color:#646E6B;
  }
`
const MenuItem = styled.a`
text-decoration-line: none;
color: #646E6B;
  &:hover{
    color: white;
  }

`

const IconWrapper = styled.div`
display: flex;
gap: 2rem;
`

const IconHref = styled.a`

`


const NETWORK_LABELS: { [chainId in ChainId]?: string | null } = {
  [ChainId.MAINNET]: 'Ethereum',
  [ChainId.RINKEBY]: 'Rinkeby',
  [ChainId.ROPSTEN]: 'Ropsten',
  // [ChainId.GÖRLI]: 'Görli',
  [ChainId.KOVAN]: 'Kovan',
  [ChainId.NEC]: 'NEC',
  [ChainId.NECTESTNET]: 'NEC',
  [ChainId.BSCMAINNET]: 'BNB',
  [ChainId.BSCTESTNET]: 'BNB',
  [ChainId.OKEX]: 'OKT',
  [ChainId.OKEX_TESTNET]: 'OKT'
}




const Banner = ({ handleClick }: { handleClick?: () => void }) => {
  const BannerList = useMemo(() => {
    return [{
      name: 'Swap',
      link: '/swap',
      path: '/swap'
    }, {
      name: 'Liquidity',
      link: '/add/NEC',
      path: '/add'
    }, {
      name: 'Pools',
      link: '/pool',
      path: '/pool'
    }, {
      name: 'More',
      link: '',
      path: '',
      children: [{
        name: 'Doc',
        link: '',
        path: ''
      }]
    }]
  }, []);
  const [isOpen, setIsOpen] = useState(false)
  const location = useLocation();
  const node = useRef<HTMLDivElement>()
  useOnClickOutside(node, isOpen ? () => setIsOpen(false) : undefined)

  return <BannerElement>
    {
      BannerList.map((item, index) => {
        return !item.children ? <BannerItem to={item.link} onClick={() => handleClick?.()} active={location.pathname.indexOf(item.path) !== -1} key={index}>
          {item.name}
        </BannerItem> : <BannerItem1 isMobile={isMobile} ref={node as any} onClick={() => setIsOpen(isMobile ? !isOpen : true)} active={false} key={index}>
          <div> {item.name} <StyledDropDown /></div>
          {
            isOpen && (!isMobile ? <MenuFlyout className='menu-glyout'>
              {
                item.children.map((child) => {
                  return <MenuItem href={child.link} target='_blank' key={child.name}>{child.name}</MenuItem>
                })
              }
              <IconWrapper>
                <IconHref href={'https://x.com/Lemon_X_world'} target='_blank'>
                  <Twitter />
                </IconHref>

                <IconHref href={'https://discord.gg/8RbVSk9HPj'} target='_blank'>
                  <Discord />
                </IconHref>
              </IconWrapper>
            </MenuFlyout> : <>
              {
                item.children.map((child) => {
                  return <MenuItem href={child.link} target='_blank' key={child.name}>{child.name}</MenuItem>
                })
              }
              <IconWrapper>
                <IconHref href={'https://x.com/Lemon_X_world'} target='_blank'>
                  <Twitter />
                </IconHref>

                <IconHref href={'https://discord.gg/8RbVSk9HPj'} target='_blank'>
                  <Discord />
                </IconHref>
              </IconWrapper>
            </>)
          }
        </BannerItem1>
      })
    }
  </BannerElement >
}

export default function Header() {
  const { account, chainId } = useActiveWeb3React()
  const userEthBalance = useETHBalances(account ? [account] : [])?.[account ?? '']
  // const [isDark] = useDarkModeManager()
  // console.log(userEthBalance)
  const [isOpen, setIsOpen] = useState(false)

  return (
    <HeaderFrame>
      <RowBetween style={{ alignItems: 'flex-start' }} padding={isMobile ? '1rem 1rem' : '2rem'}>
        <HeaderElement>
          <Title href=".">
            <UniIcon>
              {/* <img src={isDark ? LogoDark : Logo} alt="logo" /> */}
              <img src="images/logo.png" alt="logo" style={{ width: '40px' }} />
            </UniIcon>

            <TitleText>
              {/* <img style={{ marginLeft: '4px', marginTop: '0px' }} src={isDark ? WordmarkDark : Wordmark} alt="logo" /> */}
            </TitleText>
          </Title>
          {isMobile && <div onClick={() => setIsOpen(true)}>
            {/* <img src={isDark ? LogoDark : Logo} alt="logo" /> */}
            <img src="images/menu.png" alt="menu" style={{ width: '18px', marginLeft: '16px' }} />
            <img src="images/down.png" alt="down" style={{ width: '8px', marginTop: '-6px', marginLeft: '6px' }} />
          </div>}
          {!isMobile && <Banner />}
        </HeaderElement>

        <HeaderControls>
          <HeaderElement>
            <TestnetWrapper>
              {!isMobile && chainId && NETWORK_LABELS[chainId] && <NetworkCard>{NETWORK_LABELS[chainId]}</NetworkCard>}
            </TestnetWrapper>
            <AccountElement active={!!account} style={{ pointerEvents: 'auto' }}>
              {account && userEthBalance ? (
                <BalanceText style={{ flexShrink: 0 }} pl="0.75rem" pr="0.5rem" fontWeight={500}>
                  {userEthBalance?.toSignificant(4)} {userEthBalance.currency.symbol}
                </BalanceText>
              ) : null}
              <Web3Status />
            </AccountElement>
          </HeaderElement>
          <HeaderElementWrap>
            {/* <VersionSwitch /> */}
            <Settings />
            {/* <Menu /> */}
          </HeaderElementWrap>
        </HeaderControls>
      </RowBetween>
      <Modal isOpen={isOpen} onDismiss={() => setIsOpen(false)}>
        <Wrapper>
          <CloseIcon onClick={() => setIsOpen(false)}>
            <CloseColor />
          </CloseIcon>
          <Banner handleClick={() => setIsOpen(false)} />
        </Wrapper>
      </Modal>
    </HeaderFrame>
  )
}
